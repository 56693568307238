import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/common/v5/layout';
import SEO from '@/components/seo';
import {
  Hero,
  Metrics,
  EvaluateObserveProtect,
  Integrations,
} from '@/components/homepage/v6';
import { SanityHomepageV2 } from '@/types/homepage-v2';
import { DataProvider } from '@/components/DataProvider';

const Index = ({ data }: { data: { sanityHomepageV2: SanityHomepageV2 } }) => {
  return (
    <Layout>
      <DataProvider data={data}>
        <div className="bg-white">
          <Hero />
          <Metrics />
          <EvaluateObserveProtect />
          <Integrations />
        </div>
      </DataProvider>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query HomePageQuery {
    sanityHomepageV2 {
      _type
      _id
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        titlePartOne
        titlePartTwo
        titlePartThree
        _rawSubtitle
        trustedByAiLeaders
        ctaLink {
          _type
          label
          slug {
            current
          }
        }
        image {
          asset {
            url
          }
        }
        rightToLeftLogos {
          _key
          asset {
            url
          }
        }
        leftToRightLogos {
          _key
          asset {
            url
          }
        }
      }
      measurement {
        ctaLink {
          _type
          label
          slug {
            current
          }
        }
        _rawTitle
        subtitle
        flowDiagramTopTitle
        flowDiagramTopBoxOne
        flowDiagramTopBoxTwo
        flowDiagramTopBoxThree
        flowDiagramTopBoxFour
        flowDiagramMiddleTitle
        flowDiagramMiddleBoxOne
        flowDiagramBottomTitle
        flowDiagramBottomBoxOne
        boxes {
          _key
          title
          subtitle
        }
      }
      research {
        boxes {
          _key
          title
          subtitle
        }
        _rawSubtitle
        title
        ctaLink {
          _type
          label
          slug {
            current
          }
        }
        image {
          asset {
            url
          }
        }
      }
      product {
        defaultItemValue
        items {
          _key
          ctaLink {
            _type
            label
            url
          }
          image {
            asset {
              url
            }
          }
          imageMobile {
            asset {
              url
            }
          }
          buttonValue
          buttonLabel
          title
          _rawDescription
          icon {
            asset {
              url
            }
          }
          iconLabel
          pills {
            title
            _key
          }
        }
      }
      features {
        title
        boxOneTitle
        boxOneImage {
          asset {
            url
          }
        }
        boxTwoTitle
        boxTwoImage {
          asset {
            url
          }
        }
        boxTwoIconOne {
          asset {
            url
          }
        }
        boxTwoIconTwo {
          asset {
            url
          }
        }
        boxTwoIconThree {
          asset {
            url
          }
        }
        boxThirdTitle
        boxThirdImage {
          asset {
            url
          }
        }
        boxFourTitle
        boxFourImage {
          asset {
            url
          }
        }
        boxFiveTitle
        boxFiveImage {
          asset {
            url
          }
        }
        ctaLink {
          _type
          label
          slug {
            current
          }
        }
      }
      integration {
        title
        subtitle
        rightToLeftLogos {
          _key
          asset {
            url
          }
        }
        leftToRightLogos {
          _key
          asset {
            url
          }
        }
      }
      cta {
        title
        ctaLink {
          _type
          label
          slug {
            current
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const {
    seo: { metaTitle, metaDescription, image },
  } = data.sanityHomepageV2;

  return (
    <SEO
      title={metaTitle}
      description={metaDescription}
      image={'/home-v2/homepage-og-image.png'}
      imageWidth={
        image ? image.asset.localFile.childImageSharp.fixed.width : null
      }
      imageHeight={
        image ? image.asset.localFile.childImageSharp.fixed.height : null
      }
    />
  );
};
